import { IComponentStyles } from "@fluentui/foundation-legacy/lib/IComponent";
import {
  createTheme,
  DefaultPalette,
  FontWeights,
  FontSizes,
  ILabelStyles,
  IStackStyles,
  IStackTokens,
  IStyleSet,
  ITextStyles,
  Label,
  Stack,
  Text,
  ThemeProvider,
} from "@fluentui/react";

export default function Proyecto() {
  const stackHeaderStyles: Partial<IStackStyles> = {
    root: {
      borderRadius: 20,
      width: "960px",
      margin: "10 auto 10 auto",
      color: "#605e5c",
      backgroundColor: DefaultPalette.themeTertiary,
      height: "5vh",
    },
  };
  const labelHeaderTopStyles: Partial<IStyleSet<ILabelStyles>> = {
    root: {
      color: DefaultPalette.white,
      fontSize: 22,
      fontWeight: FontWeights.semibold,
      margin: "0 auto",
    },
  };

  const stackTokens: IStackTokens = { childrenGap: 15 };
  const stackStyles: Partial<IStackStyles> = {
    root: {
      borderRadius: 20,
      width: "960px",
      margin: "0 auto",
      color: "#605e5c",
      backgroundColor: DefaultPalette.themeTertiary,
      height: "85vh",
      padding: 20,
      marginTop: 10,
    },
  };
  const textStyles: ITextStyles = {
    root: {
      color: DefaultPalette.white,
    },
  };
  const themeUl = createTheme({
    palette: {
      themePrimary: "#71afe5",
      themeLighterAlt: "#f9fcfe",
      themeLighter: "#e6f1fb",
      themeLight: "#d2e6f7",
      themeTertiary: "#a6cdf0",
      themeSecondary: "#80b8e9",
      themeDarkAlt: "#659dcf",
      themeDark: "#5585ae",
      themeDarker: "#3f6281",
      neutralLighterAlt: "#faf9f8",
      neutralLighter: "#f3f2f1",
      neutralLight: "#edebe9",
      neutralQuaternaryAlt: "#e1dfdd",
      neutralQuaternary: "#d0d0d0",
      neutralTertiaryAlt: "#c8c6c4",
      neutralTertiary: "#d8ebfd",
      neutralSecondary: "#b1d8fa",
      neutralPrimaryAlt: "#8dc6f8",
      neutralPrimary: "#7ebff7",
      neutralDark: "#6091bc",
      black: "#476b8b",
      white: "#ffffff",
    },
  });
  const stackDetailsTokens: IStackTokens = {
    childrenGap: 50,
  };
  const stackDetailProject: Partial<IStackStyles> = {
    root: {
      width: "100%",
      margin: "0 auto",
      color: "#605e5c",
      backgroundColor: DefaultPalette.white,
      height: "25%",
      font: FontSizes.mediumPlus,
    },
  };
  const stackDetails: Partial<IStackStyles> = {
    root: {
      borderRadius: 20,
      width: "100%",
      margin: "0 auto",
      color: "#605e5c",
      backgroundColor: DefaultPalette.white,
      height: "85vh",
      padding: 20,
    },
  };

  return (
    <>
      <Stack
        horizontal
        horizontalAlign="center"
        verticalAlign="center"
        styles={stackHeaderStyles}
      >
        <Label styles={labelHeaderTopStyles}>
          Descripción del Proyecto Realizado
        </Label>
      </Stack>
      <Stack
        horizontalAlign="start"
        verticalAlign="start"
        verticalFill
        styles={stackStyles}
        tokens={stackTokens}
      >
        <Text block variant={"mediumPlus"} styles={textStyles}>
          Proyecto realizado como practical final del curso Electrónica Aplicada
          a Internet de las Cosas.
        </Text>
        <Text block variant={"mediumPlus"} styles={textStyles}>
          Curso tomado en línea en Polotic Misiones de Argentina impartido por
          los profesores Victoria y Maximiliano.
        </Text>
        <Text block variant={"mediumPlus"} styles={textStyles}>
          Mi eterno agradecimiento a mis profesores y al Gobierno de Misiones,
          Argentina.
        </Text>
        <Label styles={labelHeaderTopStyles}>
          El proyecto se creó utilizando:
        </Label>
        <Stack
          horizontalAlign="center"
          styles={stackDetails}
          tokens={stackDetailsTokens}
        >
          <Stack
            horizontal
            horizontalAlign="center"
            styles={stackDetailProject}
            tokens={stackDetailsTokens}
          >
            <ThemeProvider theme={themeUl}>
              <ul>
                <li role="list">Visual Studio Code</li>
                <li role="list">Ide Arduino 1.8.16</li>
                <li role="list">Net 5 Core</li>
                <li role="list">Lenguaje C#</li>
                <li role="list">Microsoft SQL Express 2014</li>
                <li role="list">React 17</li>
                <li role="list">Microsoft Fluent UI 8.46.0</li>
              </ul>
            </ThemeProvider>
            <ThemeProvider theme={themeUl}>
              <ul>
                <li role="list">ESP-8266 NodeMCU</li>
                <li role="list">Sensor de temperatura Grove V1.2</li>
                <li role="list">Led Verde</li>
                <li role="list">Resistencia 200 Ohm</li>
                <li role="list">Protoboard</li>
                <li role="list">Jumpers Dupont</li>
              </ul>
            </ThemeProvider>
          </Stack>
          <iframe
            title="Proyecto Final EA IoT"
            width="600"
            height="100%"
            src="https://www.youtube.com/embed/zT8HvwFSxmM?autoplay=2&mute=1"
          ></iframe>
        </Stack>
      </Stack>
    </>
  );
}
