import {
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  DefaultPalette,
  SelectionMode,
  Spinner,
  SpinnerSize,
  IDetailsListStyles,
} from "@fluentui/react";
import axios, { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { weather } from "../../models/weather.model";
import * as signalR from "@microsoft/signalr";

export default function DetailistWeather(props: DetailsListProps) {
  // SignalR Tiempo Real
  const [conexionSignalR] = useState<any>(
    new signalR.HubConnectionBuilder()
      .withUrl("/temperaturaHub")
      .withAutomaticReconnect()
      .build()
  );

  // Escuchar conecciones SignalR
  const escucharConexion = () => {
    if (conexionSignalR) {
      conexionSignalR
        .start()
        .then(() => {
          conexionSignalR.on(
            "UpdateDetalisListTemperatura",
            (data: weather) => {
              // se hace spread del elemento a los items del details
              setItems((dataActual) => [data, ...dataActual]);
              props.temperaturaActual(`${data.temperatura}°C`);
            }
          );
        })
        .catch((error: any) => console.log(error));
    }
  };
  // Hata Aqui SignalR
  const _columns: IColumn[] = [
    {
      key: "column1",
      name: "Dispositivo",
      fieldName: "dispositivo",
      minWidth: 100,
      maxWidth: 120,
      isResizable: true,
    },
    {
      key: "column2",
      name: "Fecha",
      fieldName: "fecha",
      minWidth: 200,
      maxWidth: 250,
      isResizable: true,
    },
    {
      key: "column3",
      name: "Temperatura",
      fieldName: "temperatura",
      minWidth: 90,
      maxWidth: 95,
      isResizable: true,
    },
  ];

  const detailsListStyles: IDetailsListStyles = {
    root: {
      background: DefaultPalette.white,
      height: "100%",
    },
    focusZone: {},
    headerWrapper: {},
    contentWrapper: {},
  };
  const respuestas: weather[] = [];

  const [items, setItems] = useState(respuestas);
  const [isLoaded, setLoaded] = useState(false);

  useEffect(() => {
    escucharConexion();
    axios
      .get("/api/Temperatura")
      .then((respuesta: AxiosResponse<weather[]>) => {
        setItems(respuesta.data);
        props.temperaturaActual(`${respuesta.data[0].temperatura}°C`);
      })
      .finally(() => {
        setLoaded(true);
      })
      .catch((error) => {
        console.log("Error en get");
      });
  }, []);

  return (
    <>
      {!isLoaded ? (
        <Spinner
          label="Cargando temperaturas..."
          ariaLive="assertive"
          labelPosition="top"
          size={SpinnerSize.large}
        />
      ) : (
        <></>
      )}
      {isLoaded ? (
        <DetailsList
          styles={detailsListStyles}
          selectionMode={SelectionMode.none}
          items={items}
          columns={_columns}
          setKey="set"
          layoutMode={DetailsListLayoutMode.fixedColumns}
          checkButtonAriaLabel="select row"
        />
      ) : (
        <></>
      )}
    </>
  );
}

interface DetailsListProps {
  temperaturaActual(temperatura: string): void;
}
