import {
  ICommandBarItemProps,
  CommandBar,
  IStackStyles,
  Stack,
  IStackTokens,
  ICommandBarStyles,
  DefaultPalette,
  CommandBarButton,
  IComponentAsProps,
} from "@fluentui/react";
import "./App.css";
import { initializeIcons } from "@fluentui/react/lib/Icons";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LandingPage from "./components/landingPage/LandingPage";
import Proyecto from "./components/proyecto/Proyecto";
import { Link } from "react-router-dom";

function App() {
  initializeIcons();
  // Custom button for use with Fluent-UI CommandBar
  const CustomButton: React.FunctionComponent<IComponentAsProps<ICommandBarItemProps>> = props => {
    return (<Link to={props.href!}> <CommandBarButton {...(props as any)} text={(props.text || props.name)}/></Link>);
  };
  const stackHeaderCommandStyles: Partial<IStackStyles> = {
    root: {
      width: "960px",
      margin: "10 auto 10 auto",
    },
  };
  const stackTokens: IStackTokens = { childrenGap: 10 };

  const _commandStyle: Partial<ICommandBarStyles> = {
    root: {
      width: "960px",
      margin: "0 auto",
      textAlign: "center",
      color: "#605e5c",
    },
  };
  // items
  const _items: ICommandBarItemProps[] = [
    {
      key: "home",
      text: "Home",
      cacheKey: "myCacheKey", // changing this key will invalidate this item's cache
      iconProps: { iconName: "Home" },
      commandBarButtonAs: CustomButton,
      href:'/',
    },
    {
      key: "proyecto",
      text: "Proyecto",
      iconProps: { iconName: "PlanView" },
      commandBarButtonAs: CustomButton,
      href:"/Proyecto"
    },
  ];

  const mainStackStyles: IStackStyles = {
    root: {
      backgroundColor: DefaultPalette.white,
      color: DefaultPalette.themeTertiary,
      height: "100%",
      width: "100%",
      textAlign: "Left",
      padding: 10,
    },
  };

  return (
    <>
      <BrowserRouter>
        <Stack
          verticalAlign="start"
          horizontalAlign="center"
          styles={mainStackStyles}
          tokens={stackTokens}
        >
          <Stack
            horizontal
            horizontalAlign="center"
            verticalAlign="start"
            styles={stackHeaderCommandStyles}
          >
            <CommandBar items={_items} styles={_commandStyle} />
          </Stack>
          <Routes>
            <Route key="Home" path="/" element={<LandingPage />} />
            <Route key="Proyecto" path="/Proyecto" element={<Proyecto />} />
          </Routes>
        </Stack>
      </BrowserRouter>
    </>
  );
}
export default App;
