import React, { useState, useEffect } from "react";
import {
  IStackStyles,
  Stack,
  IStackTokens,
  DefaultPalette,
  createTheme,
  Label,
  IStyleSet,
  ILabelStyles,
  FontWeights,
  Toggle,
  IToggleStyles,
  ThemeProvider,
  Link,
} from "@fluentui/react";
import "../../App.css";
import DetailsListWeather from "../detailsListWeather/DetailsListWeather";
import TemperaturaActual from "../temperaturaActual/TemperaturaActual";
import axios, { AxiosResponse } from "axios";
import { dispositivo } from "../../models/dispositivo.module";

export default function LandingPage() {
  const myTheme = createTheme({
    palette: {
      themePrimary: "#71afe5",
      themeLighterAlt: "#f9fcfe",
      themeLighter: "#e6f1fb",
      themeLight: "#d2e6f7",
      themeTertiary: "#a6cdf0",
      themeSecondary: "#80b8e9",
      themeDarkAlt: "#659dcf",
      themeDark: "#5585ae",
      themeDarker: "#3f6281",
      neutralLighterAlt: "#faf9f8",
      neutralLighter: "#f3f2f1",
      neutralLight: "#edebe9",
      neutralQuaternaryAlt: "#e1dfdd",
      neutralQuaternary: "#d0d0d0",
      neutralTertiaryAlt: "#c8c6c4",
      neutralTertiary: "#d8ebfd",
      neutralSecondary: "#b1d8fa",
      neutralPrimaryAlt: "#8dc6f8",
      neutralPrimary: "#7ebff7",
      neutralDark: "#6091bc",
      black: "#476b8b",
      white: "#ffffff",
    },
  });
  const stackHeaderStyles: Partial<IStackStyles> = {
    root: {
      borderRadius: 20,
      width: "960px",
      margin: "10 auto 10 auto",
      color: "#605e5c",
      backgroundColor: DefaultPalette.themeTertiary,
      height: "5vh",
    },
  };
  const stackTokens: IStackTokens = { childrenGap: 15 };
  const stackStyles: Partial<IStackStyles> = {
    root: {
      borderRadius: 20,
      width: "960px",
      margin: "0 auto",
      color: "#605e5c",
      backgroundColor: DefaultPalette.themeTertiary,
      height: "85vh",
      padding: 20,
      marginTop: 10,
    },
  };
   
  // Stack Detalle temperatura
  const stackTempeartureDetailsStyles: IStackStyles = {
    root: {
      borderRadius: 20,
      backgroundColor: DefaultPalette.white,
      padding: 20,
    },
  };

  // Style contenedor derecho Actual y Switch Dispositiov
  const stackDerechoStyles: IStackStyles = {
    root: {
      backgroundColor: DefaultPalette.themeTertiary,
      height: "80vh",
      width: 400,
      marginLeft: 0,
    },
  };
  const labelStyles: Partial<IStyleSet<ILabelStyles>> = {
    root: {
      color: DefaultPalette.white,
      fontSize: 18,
      fontWeight: FontWeights.semibold,
      margin: "0",
      textAlign: "left",
    },
  };

  const labelHeaderStyles: Partial<IStyleSet<ILabelStyles>> = {
    root: {
      color: DefaultPalette.themeTertiary,
      fontSize: 18,
      fontWeight: FontWeights.semibold,
      margin: "0 auto",
    },
  };

  const labelHeaderTopStyles: Partial<IStyleSet<ILabelStyles>> = {
    root: {
      color: DefaultPalette.white,
      fontSize: 22,
      fontWeight: FontWeights.semibold,
      margin: "0 auto",
    },
  };

  const toggleSwithStyles: Partial<IToggleStyles> = {
    root: {
      margin: 20,
    },
    text: {
      color: DefaultPalette.themeTertiary,
    },
    label: {
      color: DefaultPalette.themeTertiary,
    },
  };

  // Style contenedor Temperatura Actual y Switch Dispositiov
  const stackTemperatureActualStyles: IStackStyles = {
    root: {
      borderRadius: 20,
      backgroundColor: DefaultPalette.white,
      height: 200,
      width: "100%",
    },
  };
  const stackLabelStyles: IStackStyles = {
    root: {
      textAlign: "center",
      horizontalAlign: "center",
      width: "100%",
    },
  };

  const stackSwitchLedStyles: IStackStyles = {
    root: {
      borderRadius: 20,
      backgroundColor: DefaultPalette.white,
      height: 100,
      width: "100%",
    },
  };

  const stackProyectodStyles: IStackStyles = {
    root: {
      borderRadius: 20,
      backgroundColor: DefaultPalette.white,
      color: DefaultPalette.themeTertiary,
      height: "34VH",
      width: "100%",
      textAlign: "Left",
      padding: 10,
    },
  };
  // Estados
  const [temperaturaActual, setTemperaturaActual] = useState("0°C");
  const [isChecked, setIsChecked] = useState<boolean>(false);

  useEffect(() => {
    axios
      .get("/api/Dispositivo/1")
      .then((respuesta: AxiosResponse<dispositivo>) => {
        setIsChecked(respuesta.data.encendido);
        console.log(respuesta.data.encendido);
      })
      .finally(() => {})
      .catch((error) => {
        console.log("Error en get");
      });
  }, []);
  return (
    <>
      <Stack
        horizontal
        horizontalAlign="center"
        verticalAlign="center"
        styles={stackHeaderStyles}
      >
        <Label styles={labelHeaderTopStyles}>
          Practica Final Electrónica Aplicada a Internet de las Cosas
        </Label>
      </Stack>
      <Stack
        horizontal
        horizontalAlign="start"
        verticalAlign="start"
        verticalFill
        styles={stackStyles}
        tokens={stackTokens}
      >
        <Stack
          horizontalAlign="start"
          verticalAlign="start"
          verticalFill
          styles={stackTempeartureDetailsStyles}
          tokens={stackTokens}
        >
          <Label styles={labelHeaderStyles}>Temperatura Historial</Label>
          <DetailsListWeather
            temperaturaActual={(e: string) => setTemperaturaActual(e)}
          />
        </Stack>
        <Stack
          horizontalAlign="end"
          verticalAlign="start"
          verticalFill
          styles={stackDerechoStyles}
        >
          <Stack styles={stackLabelStyles}>
            <Label styles={labelStyles}>Temperatura Actual</Label>
          </Stack>
          <Stack
            horizontalAlign="center"
            verticalAlign="center"
            verticalFill
            styles={stackTemperatureActualStyles}
          >
            <TemperaturaActual
              temperatura={temperaturaActual}
              ciudad="Colima"
              pais="México"
            />
          </Stack>
          <Stack styles={stackLabelStyles}>
            <Label styles={labelStyles}>Dispositivo</Label>
          </Stack>
          <Stack
            horizontalAlign="start"
            styles={stackSwitchLedStyles}
            tokens={stackTokens}
          >
            <Toggle
              label="Lampara Cuarto"
              inlineLabel
              styles={toggleSwithStyles}
              theme={myTheme}
              checked={isChecked}
              onText="Encendido"
              offText="Apagado"
              onChange={_onChange}
            />
          </Stack>
          <Stack styles={stackLabelStyles}>
            <Label styles={labelStyles}>Motivo</Label>
          </Stack>
          <Stack horizontalAlign="start" styles={stackProyectodStyles}>
            <ThemeProvider theme={myTheme}>
              <p>
                Proyecto realizado como practica final del curso Electrónica
                Aplicada a Internet de las Cosas. <br /> <br />
                Curso tomado en línea en{" "}
                <Link href="https://polotic.misiones.gob.ar/" target="_blank">
                  <b>Polotic Misiones</b>
                </Link>{" "}
                de Argentina impartido por los profesores{" "}
                <b>Victoria y Maximiliano.</b> <br /> <br />
                Mi eterno agradecimiento a mis <b>profesores</b> y al{" "}
                <b>Gobierno de Misiones, Argentina.</b>  <br /> <br />
                <Link href="https://github.com/jrfito/EAIoTFinalSketch" target="_blank"> <b>Sketch Arduino</b></Link>
              </p>
            </ThemeProvider>
          </Stack>
        </Stack>
      </Stack>
    </>
  );

  function _onChange(
    ev: React.MouseEvent<HTMLElement>,
    checked: boolean = false
  ) {
    const dispo = {
      id: 1,
      encendido: checked,
    };
    axios
      .put("/api/Dispositivo", dispo)
      .then((respuesta: AxiosResponse<dispositivo>) => {
        setIsChecked(checked);
      })
      .catch(() => {
        console.log("Error al encender dispositivo");
      });
  }
}
