import { ILabelStyles, IStyleSet, Label } from "@fluentui/react";
import { DefaultPalette } from "@fluentui/theme";

export default function TemperaturaActual(props: temperaturaProps) {
  const labelStyles: Partial<IStyleSet<ILabelStyles>> = {
    root: {
      fontSize: 75,
      color: DefaultPalette.themeTertiary,
    },
  };
  const labelCiudadStyles: Partial<IStyleSet<ILabelStyles>> = {
    root: {
      fontSize: 30,
      color: DefaultPalette.themeTertiary,
    },
  };

  const labelPaisStyles: Partial<IStyleSet<ILabelStyles>> = {
    root: {
      fontSize: 20,
      color: DefaultPalette.themeTertiary,
    },
  };
  return (
    <>
      <Label styles={labelCiudadStyles}>{props.ciudad}</Label>
      <Label styles={labelPaisStyles}>{props.pais}</Label>
      <Label styles={labelStyles}>{props.temperatura}</Label>
    </>
  );
}

interface temperaturaProps {
  temperatura: string;
  ciudad: string;
  pais: string;
}
